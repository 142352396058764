<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="`${$t('short_redirect_url')}`">
        <template slot="tools">
          <router-link
            :to="
              `/${$route.params.shop_lang}/${$route.params.shop_name}/short-redirects/add`
            "
            class="btn btn-primary btn-xs"
          >
            Dodaj skrócony link
            <i class="fas fa-plus"></i>
          </router-link>
        </template>
        <template slot="content" slot-scope="{ setLoading }">
          <DataTable :url="'/lists/short-redirects'" @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "ShortRedirectUrlList",
  components: {
    Ibox,
    DataTable
  }
};
</script>
